import React from 'react'
import Layout from "../components/layout"
import Head from "../components/head"

const aboutPage = () => {
  return (
    <Layout>
      <Head
        title="About"
      />
      <div> About </div>
    </Layout>
  )
}

export default aboutPage